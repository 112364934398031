.pill {
    background-color: light-dark(
        var(--mantine-color-gray-1),
        var(--mantine-color-gray-8)
    );
    margin-left: 0;
    margin-right: 0;
    border-radius: var(--mantine-radius-default);
}

.cell, .edit {
    height: '100%';
    padding: var(--mantine-spacing-xxs) var(--mantine-spacing-xs);
    border-radius: var(--mantine-radius-default);
    background-color: light-dark(
        var(--mantine-color-white),
        var(--mantine-color-gray-9)
    );
}

.edit:hover {
    background-color: light-dark(
        var(--mantine-color-gray-0),
        var(--mantine-color-dark-7),
    );
}
.edit:active {
    padding-top: 4;
    padding-bottom: 0;
}

.textCell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}